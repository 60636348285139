import React from "react";
import logo from "./assets/images/gymble-logo-light.png";
import "./App.css";
import { Link } from "react-router-dom";

function App() {
    return (
        <div className='App'>
            <header className='App-header'>
                <p className='App-title'>Reserviert für...</p>
                <img src={logo} className='App-logo' alt='logo' />
                <p className='App-copyright'>
                    <Link to='/datenschutz'>Datenschutzerklärung für die Gymble App</Link>
                </p>
                <p className='App-copyright'>© 2021 Gymble</p>
            </header>
        </div>
    );
}

export default App;
