import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Datenschutzerklärung from "./Datenschutzerklärung";

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='datenschutz' element={<Datenschutzerklärung />} />
            </Routes>
        </BrowserRouter>
    );
};
