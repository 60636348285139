import React from "react";
import "./Datenschutzerklärung.css";

export default function Datenschutzerklärung() {
    return (
        <div className='App-header datenschutz'>
            <h1>Datenschutzerklärung</h1>
            <h2>Inhaltsverzeichnis</h2>
            <ul>
                <li>
                    <a href='#einleitung-ueberblick'>Einleitung und Überblick</a>
                </li>
                <li>
                    <a href='#anwendungsbereich'>Anwendungsbereich</a>
                </li>
                <li>
                    <a href='#rechtsgrundlagen'>Rechtsgrundlagen</a>
                </li>
                <li>
                    <a href='#kontaktdaten-verantwortliche'>Kontaktdaten des Verantwortlichen</a>
                </li>
                <li>
                    <a href='#speicherdauer'>Speicherdauer</a>
                </li>
                <li>
                    <a href='#rechte-dsgvo'>Rechte laut Datenschutz-Grundverordnung</a>
                </li>
                <li>
                    <a href='#sicherheit-datenverarbeitung'>Sicherheit der Datenverarbeitung</a>
                </li>
                <li>
                    <a href='#kommunikation'>Kommunikation</a>
                </li>
                <li>
                    <a href='#cookies'>Cookies</a>
                </li>
                <li>
                    <a href='#webhosting-einleitung'>Webhosting Einleitung</a>
                </li>
                <li>
                    <a href='#push-nachrichten-einleitung'>Push-Nachrichten Einleitung</a>
                </li>
                <li>
                    <a href='#audio-video-einleitung'>Audio &amp; Video Einleitung</a>
                </li>
                <li>
                    <a href='#sonstiges-einleitung'>Sonstiges Einleitung</a>
                </li>
            </ul>
            <h2 id='einleitung-ueberblick'>Einleitung und Überblick</h2>
            <p>
                Wir haben diese Datenschutzerklärung (Fassung 10.02.2022-111943338) verfasst, um Ihnen gemäß der
                Vorgaben der
                <a
                    href='https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111943338'
                    rel='noopener'
                >
                    Datenschutz-Grundverordnung (EU) 2016/679
                </a>
                und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als
                Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211;
                verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die
                verwendeten Begriffe sind geschlechtsneutral zu verstehen.
                <br />
                <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
            </p>
            <p>
                Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe.
                Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
                möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische
                <strong>Begriffe leserfreundlich erklärt</strong>, <strong>Links</strong> zu weiterführenden
                Informationen geboten und <strong>Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in
                klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene
                Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht
                möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im
                Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden
                Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die
                Sie noch nicht kannten.
                <br />
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
                verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
                Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.
            </p>
            <h2 id='anwendungsbereich'>Anwendungsbereich</h2>
            <p>
                Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten
                und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten.
                Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel
                Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten
                sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online
                oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
            </p>
            <ul>
                <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                <li>Social Media Auftritte und E-Mail-Kommunikation</li>
                <li>mobile Apps für Smartphones und andere Geräte</li>
            </ul>
            <p>
                <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
                Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb
                dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
                informieren.
            </p>
            <h2 id='rechtsgrundlagen'>Rechtsgrundlagen</h2>
            <p>
                In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
                Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
                ermöglichen, personenbezogene Daten zu verarbeiten.
                <br />
                Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS
                UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
                online auf EUR-Lex, dem Zugang zum EU-Recht, unter
                <a
                    href='https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111943338'
                    rel='noopener'
                >
                    https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679
                </a>
                nachlesen.
            </p>
            <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
            <ol>
                <li>
                    <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
                    gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer
                    eingegebenen Daten eines Kontaktformulars.
                </li>
                <li>
                    <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
                    Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen
                    Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
                </li>
                <li>
                    <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer
                    rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich
                    verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
                    personenbezogene Daten.
                </li>
                <li>
                    <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
                    Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
                    personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website
                    sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein
                    berechtigtes Interesse.
                </li>
            </ol>
            <p>
                Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung
                öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf.
                Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle
                ausgewiesen.
            </p>
            <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
            <ul>
                <li>
                    In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                    Verarbeitung personenbezogener Daten (<strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>
                    .
                </li>
                <li>
                    In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz
                    <strong> BDSG</strong>.
                </li>
            </ul>
            <p>
                Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den
                folgenden Abschnitten darüber.
            </p>
            <h2 id='kontaktdaten-verantwortliche'>Kontaktdaten des Verantwortlichen</h2>
            <p>
                Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen
                Person bzw. Stelle:
                <br />
                <span>
                    Maximilian Hraschan
                    <br />
                    Jurekgasse 1/2/2 1150 Wien
                </span>
            </p>
            <p>
                E-Mail: <a href='mailto:office@mhraschan.com'>office@mhraschan.com</a>
                <br />
                Telefon: <a href='tel:+436641545456'>+436641545456</a>
            </p>
            <h2 id='speicherdauer'>Speicherdauer</h2>
            <p>
                Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
                Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das
                bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr
                vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach
                Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.
            </p>
            <p>
                Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen,
                werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.
            </p>
            <p>
                Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir
                weitere Informationen dazu haben.
            </p>
            <h2 id='rechte-dsgvo'>Rechte laut Datenschutz-Grundverordnung</h2>
            <p>
                Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten
                Verarbeitung von Daten kommt:
            </p>
            <ul>
                <li>
                    Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten.
                    Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden
                    Informationen zu erfahren:
                    <ul>
                        <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                        <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                        <li>
                            wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die
                            Sicherheit garantiert werden kann;
                        </li>
                        <li>wie lange die Daten gespeichert werden;</li>
                        <li>
                            das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und
                            dem Widerspruchsrecht gegen die Verarbeitung;
                        </li>
                        <li>
                            dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden
                            Sie weiter unten);
                        </li>
                        <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                        <li>
                            ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem
                            persönlichen Profil von Ihnen zu gelangen.
                        </li>
                    </ul>
                </li>
                <li>
                    Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten
                    richtig stellen müssen, falls Sie Fehler finden.
                </li>
                <li>
                    Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret
                    bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
                </li>
                <li>
                    Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir
                    die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
                </li>
                <li>
                    Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf
                    Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
                </li>
                <li>
                    Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der
                    Verarbeitung mit sich bringt.
                    <ul>
                        <li>
                            Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse,
                            Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert,
                            können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie
                            möglich, ob wir diesem Widerspruch rechtlich nachkommen können.
                        </li>
                        <li>
                            Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art
                            der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                            Direktmarketing verwenden.
                        </li>
                        <li>
                            Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der
                            Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling
                            verwenden.
                        </li>
                    </ul>
                </li>
                <li>
                    Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer
                    automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.
                </li>
            </ul>
            <p>
                <strong>Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete
                verantwortliche Stelle bei uns zu kontaktieren!
            </p>
            <p>
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
                Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter
                <a href='https://www.dsb.gv.at/?tid=111943338' rel='noopener'>
                    https://www.dsb.gv.at/
                </a>
                finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere
                Informationen können Sie sich an die
                <a href='https://www.bfdi.bund.de/DE/Home/home_node.html' rel='noopener'>
                    Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)
                </a>
                wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:
            </p>
            <h2 id='oesterreich-datenschutzbehoerde'>Österreich Datenschutzbehörde</h2>
            <p>
                <strong>Leiterin: </strong>Mag. Dr. Andrea Jelinek
                <strong>
                    <br />
                    Adresse:
                </strong>
                Barichgasse 40-42, 1030 Wien
                <strong>
                    <br />
                    Telefonnr.:
                </strong>
                +43 1 52 152-0
                <strong>
                    <br />
                    E-Mail-Adresse:
                </strong>
                <a href='mailto:dsb@dsb.gv.at' rel='noopener'>
                    dsb@dsb.gv.at
                </a>
                <strong>
                    <br />
                    Website:
                </strong>
                <a href='https://www.dsb.gv.at/' rel='noopener'>
                    https://www.dsb.gv.at/
                </a>
            </p>
            <h2 id='sicherheit-datenverarbeitung'>Sicherheit der Datenverarbeitung</h2>
            <p>
                Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
                umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten.
                Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren
                Daten auf persönliche Informationen schließen können.
            </p>
            <p>
                Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch
                datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B.
                Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende
                Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.
            </p>
            <h2 id='tls-verschluesselung-https'>TLS-Verschlüsselung mit https</h2>
            <p>
                TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das
                Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten
                abhörsicher im Internet zu übertragen.
                <br />
                Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver
                abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.
            </p>
            <p>
                Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch
                Technikgestaltung
                <a
                    href='https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=111943338'
                    rel='noopener'
                >
                    Artikel 25 Absatz 1 DSGVO
                </a>
                ). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
                Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.
                <br />
                Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol
                <img
                    role='img'
                    src='https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg'
                    width='17'
                    height='18'
                />
                links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des
                Schemas https (anstatt http) als Teil unserer Internetadresse.
                <br />
                Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach
                &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu
                erhalten.
            </p>
            <h2 id='kommunikation'>Kommunikation</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Kommunikation Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular
                            kommunizieren
                            <br />
                            &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene
                            Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart
                            <br />
                            &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.
                            <br />
                            &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es
                zur Verarbeitung personenbezogener Daten kommen.
            </p>
            <p>
                Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden
                Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz
                vorschreibt.
            </p>
            <h3>Betroffene Personen</h3>
            <p>
                Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
                Kommunikationswege den Kontakt zu uns suchen.
            </p>
            <h3>Telefon</h3>
            <p>
                Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
                Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer
                im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden
                gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
            </p>
            <h3>E-Mail</h3>
            <p>
                Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät
                (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem
                E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
                Vorgaben erlauben.
            </p>
            <h3>Online Formulare</h3>
            <p>
                Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert
                und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
                Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
            </p>
            <h3>Rechtsgrundlagen</h3>
            <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
            <ul>
                <li>
                    Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern
                    und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
                </li>
                <li>
                    Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags
                    mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für
                    vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;
                </li>
                <li>
                    Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche
                    Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen
                    wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation
                    effizient betreiben zu können.
                </li>
            </ul>
            <h2 id='cookies'>Cookies</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Cookies Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Besucher der Website
                            <br />
                            &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten
                            bzw. beim Hersteller der Software, der das Cookie setzt.
                            <br />
                            &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu
                            finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.
                            <br />
                            &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                            variieren
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit.f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was sind Cookies?</h3>
            <p>
                Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
                <br />
                Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
                Datenschutzerklärung besser verstehen.
            </p>
            <p>
                Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
                beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites
                speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
            </p>
            <p>
                Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites
                verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
                Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
                gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
                &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei
                der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
            </p>
            <p>
                Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
                Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
                Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet
                Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in
                anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
            </p>
            <p>
                Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
                Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches
                der Browser erneut verwendet, sobald eine andere Seite angefordert wird.
            </p>
            <p>
                <img
                    role='img'
                    src='https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg'
                    alt='HTTP Cookie Interaktion zwischen Browser und Webserver'
                    width='100%'
                />
            </p>
            <p>
                Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt
                von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics)
                erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
                Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
                Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch
                nicht auf Informationen Ihres PCs zugreifen.
            </p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <p>
                <strong>Name:</strong> _ga
                <br />
                <strong>Wert:</strong> GA1.2.1326744211.152111943338-9
                <br />
                <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher
                <br />
                <strong>Ablaufdatum:</strong> nach 2 Jahren
            </p>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
            <ul>
                <li>Mindestens 4096 Bytes pro Cookie</li>
                <li>Mindestens 50 Cookies pro Domain</li>
                <li>Mindestens 3000 Cookies insgesamt</li>
            </ul>
            <h3>Welche Arten von Cookies gibt es?</h3>
            <p>
                Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in
                den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
                verschiedenen Arten von HTTP-Cookies eingehen.
            </p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
                <strong>
                    Unerlässliche Cookies
                    <br />
                </strong>
                Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht
                es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
                und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der
                User sein Browserfenster schließt.
            </p>
            <p>
                <strong>
                    Zweckmäßige Cookies
                    <br />
                </strong>
                Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
                Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
                Browsern gemessen.
            </p>
            <p>
                <strong>
                    Zielorientierte Cookies
                    <br />
                </strong>
                Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
                Schriftgrößen oder Formulardaten gespeichert.
            </p>
            <p>
                <strong>
                    Werbe-Cookies
                    <br />
                </strong>
                Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
                Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
            </p>
            <p>
                Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
                zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
            </p>
            <p>
                Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir
                <a href='https://datatracker.ietf.org/doc/html/rfc6265'>
                    https://datatracker.ietf.org/doc/html/rfc6265
                </a>
                , dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State
                Management Mechanism&#8221;.
            </p>
            <h3>Zweck der Verarbeitung über Cookies</h3>
            <p>
                Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten
                bzw. beim Hersteller der Software, die das Cookie setzt.
            </p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>
                Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert
                werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden
                Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.
            </p>
            <h3>Speicherdauer von Cookies</h3>
            <p>
                Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden
                nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
                bleiben.
            </p>
            <p>
                Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche
                Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden
                Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei
                die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.
            </p>
            <h3>Wo werden die Daten gespeichert?</h3>
            <p>
                Ihre Daten werden auf einem Webserver von STRATO gespeichert. Alle Datenschutzinformationen hierzu
                finden sie hier:
                <a href='https://www.strato.de/datenschutz/'>Strato Datenschutzerklärung</a>
            </p>

            <h3>Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
            <p>
                Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder
                welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren
                oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle
                anderen Cookies zulassen.
            </p>
            <p>
                Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
                Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
            </p>
            <p>
                <a href='https://support.google.com/chrome/answer/95647?tid=111943338'>
                    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                </a>
            </p>
            <p>
                <a href='https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111943338'>
                    Safari: Verwalten von Cookies und Websitedaten mit Safari
                </a>
            </p>
            <p>
                <a href='https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111943338'>
                    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                </a>
            </p>
            <p>
                <a href='https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=111943338'>
                    Internet Explorer: Löschen und Verwalten von Cookies
                </a>
            </p>
            <p>
                <a href='https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=111943338'>
                    Microsoft Edge: Löschen und Verwalten von Cookies
                </a>
            </p>
            <p>
                Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie
                immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
                entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden.
                Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder
                &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.
            </p>
            <h3>Rechtsgrundlage</h3>
            <p>
                Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
                Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb
                der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In
                Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes
                (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen
                erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
            </p>
            <p>
                Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen
                <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen
                wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung
                bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.
            </p>
            <p>
                Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer
                Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <p>
                In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern
                eingesetzte Software Cookies verwendet.
            </p>
            <h2 id='webhosting-einleitung'>Webhosting Einleitung</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Webhosting Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Besucher der Website
                            <br />
                            &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs
                            <br />
                            &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser
                            und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten
                            Webhosting Provider.
                            <br />
                            &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was ist Webhosting?</h3>
            <p>
                Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten
                &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst
                sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller
                Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten
                Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
            </p>
            <p>
                Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich
                Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge,
                Mozilla Firefox und Apple Safari.
            </p>
            <p>
                Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert
                ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen
                dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting
                an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.
            </p>
            <p>
                Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während
                der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen.
                Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang
                speichern, um einen ordentlichen Betrieb zu gewährleisten.
            </p>
            <p>Zur Veranschaulichung:</p>
            <p>
                <img
                    role='img'
                    src='https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg'
                    alt='Browser und Webserver'
                    width='100%'
                />
            </p>
            <h3>Warum verarbeiten wir personenbezogene Daten?</h3>
            <p>Die Zwecke der Datenverarbeitung sind:</p>
            <ol>
                <li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
                <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
                <li>
                    Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur
                    Strafverfolgung bzw. Verfolgung von Ansprüchen
                </li>
            </ol>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>
                Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer
                auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie
            </p>
            <ul>
                <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
                <li>Browser und Browserversion (z. B. Chrome 87)</li>
                <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
                <li>
                    die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                    <a href='https://www.beispielquellsite.de/vondabinichgekommen.html/' rel='follow noopener'>
                        https://www.beispielquellsite.de/vondabinichgekommen.html/
                    </a>
                    )
                </li>
                <li>
                    den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und
                    194.23.43.121)
                </li>
                <li>Datum und Uhrzeit</li>
                <li>in Dateien, den sogenannten Webserver-Logfiles</li>
            </ul>
            <h3>Wie lange werden Daten gespeichert?</h3>
            <p>
                In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht.
                Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
                von rechtswidrigem Verhalten von Behörden eingesehen werden.
            </p>
            <p>
                <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf
                speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne
                Zustimmung weiter!
            </p>
            <h3>Rechtsgrundlage</h3>
            <p>
                Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus
                Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem
                Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich
                präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.
            </p>
            <p>
                Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung
                gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.
            </p>
            <h2 id='push-nachrichten-einleitung'>Push-Nachrichten Einleitung</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Push-Nachrichten Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Push-Nachrichten Abonnenten
                            <br />
                            &#x1f91d; Zweck: Benachrichtigung über systemrelevante und interessante Ereignisse
                            <br />
                            &#x1f4d3; Verarbeitete Daten: Eingegebene Daten bei der Registrierung, meistens auch
                            Standortdaten. Mehr Details dazu finden Sie beim jeweils eingesetzten Push-Nachrichten-Tool.
                            <br />
                            &#x1f4c5; Speicherdauer: Daten meist so lange gespeichert, wie es für die Bereitstellung der
                            Dienste nötig ist.
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit. b DSGVO (Vertrag)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was sind Push-Nachrichten?</h3>
            <p>
                Wir nutzen auf unserer Website auch sogenannte Push-Benachrichtigungsdienste, mit denen wir unsere User
                stets auf dem Laufenden halten können. Das bedeutet, wenn Sie der Nutzung von solchen Push-Nachrichten
                zugestimmt haben, können wir Ihnen mit Hilfe eines Softwaretools kurze News schicken. Push-Nachrichten
                sind eine Textnachrichten-Form, die bei Ihnen direkt auf dem Smartphone oder auf anderen Geräten wie
                etwa Tablets oder PCs erscheinen, wenn Sie sich dafür angemeldet haben. Sie bekommen diese Nachrichten
                auch, wenn Sie sich nicht auf unserer Website befinden bzw. unser Angebot nicht aktiv nutzen. Dabei
                können auch Daten über Ihren Standort und Ihr Nutzungsverhalten gesammelt und gespeichert werden.
            </p>
            <h3>Warum verwenden wir Push-Nachrichten?</h3>
            <p>
                Einerseits nutzen wir Push-Nachrichten, um unsere Leistungen, die wir mit Ihnen vertraglich vereinbart
                haben, auch vollständig erbringen zu können. Andererseits dienen die Nachrichten auch unserem
                Online-Marketing. Wir können Ihnen mit Hilfe dieser Nachrichten unser Service oder unsere Produkte
                näherbringen. Besonders wenn es Neuigkeiten in unserem Unternehmen gibt, können wir Sie darüber sofort
                informieren. Wir wollen die Vorlieben und die Gewohnheiten all unserer User so gut wie möglich
                kennenlernen, um unser Angebot laufend zu verbessern.
            </p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>
                Damit Sie Push-Nachrichten bekommen können, müssen Sie auch bestätigen, dass Sie diese Nachrichten
                erhalten wollen. Die Daten, die während des Prozesses der Einwilligung angesammelt werden, werden auch
                gespeichert, verwaltet und verarbeitet. Das ist notwendig, damit bewiesen und erkannt werden kann, dass
                ein User dem Empfangen der Push-Nachrichten zugestimmt hat. Dafür wird ein sogenannter Device-Token oder
                Push-Token in Ihrem Browser gespeichert. Für gewöhnlich werden auch die Daten Ihres Standorts bzw. des
                Standorts Ihres verwendeten Endgeräts gespeichert.
            </p>
            <p>
                Damit wir auch stets interessante und wichtige Push-Nachrichten versenden, wird der Umgang mit den
                Nachrichten auch statistisch ausgewertet. So sehen wir dann zum Beispiel, ob und wann Sie die Nachricht
                öffnen. Mit Hilfe dieser Erkenntnisse können wir unsere Kommunikationsstrategie an Ihre Wünsche und
                Interessen anpassen. Obwohl diese gespeicherten Daten Ihnen zugeordnet werden können, wollen wir Sie als
                Einzelperson nicht überprüfen. Vielmehr interessieren uns die gesammelten Daten all unserer User, damit
                wir Optimierungen vornehmen können. Welche Daten genau gespeichert werden, erfahren Sie in den
                Datenschutzerklärungen der jeweiligen Dienstanbieter.
            </p>
            <h3>Dauer der Datenverarbeitung</h3>
            <p>
                Wie lange die Daten verarbeitet und gespeichert werden, hängt in erster Linie von unserem verwendeten
                Tool ab. Weiter unten erfahren Sie mehr über die Datenverarbeitung der einzelnen Tools. In den
                Datenschutzerklärungen der Anbieter steht üblicherweise genau, welche Daten wie lange gespeichert und
                verarbeitet werden. Grundsätzlich werden personenbezogene Daten nur so lange verarbeitet, wie es für die
                Bereitstellung unserer Dienste nötig ist. Wenn Daten in Cookies gespeichert werden, variiert die
                Speicherdauer stark. Die Daten können gleich nach dem Verlassen einer Website wieder gelöscht werden,
                sie können aber auch über mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen
                Cookie im Detail ansehen, wenn Sie über die Datenspeicherung Genaueres wissen wollen. Meistens finden
                Sie in den Datenschutzerklärungen der einzelnen Anbieter auch aufschlussreiche Informationen über die
                einzelnen Cookies.
            </p>
            <h3>Rechtsgrundlage</h3>
            <p>
                Es kann auch sein, dass die Push-Nachrichten notwendig sind, damit gewisse Pflichten, die in einem
                Vertrag stehen, erfüllt werden können. Zum Beispiel, damit wir Ihnen technische oder organisatorische
                Neuigkeiten mitteilen können. Dann lautet die Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO.
            </p>
            <p>
                Wenn dies nicht der Fall ist, werden die Push-Nachrichten nur auf Grundlage Ihrer Einwilligung
                verschickt. Unsere Push-Nachrichten können insbesondere einen werbenden Inhalt haben. Die
                Push-Nachrichten können auch abhängig von Ihrem Standort, die Ihr Endgerät anzeigt, versendet werden.
                Auch die oben genannten analytischen Auswertungen basieren auf Grundlage Ihrer Einwilligung, solche
                Nachrichten empfangen zu wollen. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO. Sie können
                natürlich in den Einstellungen Ihre Einwilligung jederzeit widerrufen bzw. diverse Einstellungen ändern.
            </p>
            <h2 id='audio-video-einleitung'>Audio &amp; Video Einleitung</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Audio &amp; Video Datenschutzerklärung Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Besucher der Website
                            <br />
                            &#x1f91d; Zweck: Optimierung unserer Serviceleistung
                            <br />
                            &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten,
                            Informationen zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.
                            <br />
                            Mehr Details dazu finden Sie weiter unten in den entsprechenden Datenschutztexten.
                            <br />
                            &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den
                            Dienstzweck nötig sind
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit. f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was sind Audio- und Videoelemente?</h3>
            <p>
                Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie sich direkt über unsere
                Website etwa Videos ansehen oder Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern
                zur Verfügung gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter
                bezogen.
            </p>
            <p>
                Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder
                Spotify. Die Nutzung dieser Portale ist in der Regel kostenlos, es können aber auch kostenpflichtige
                Inhalte veröffentlicht werden. Mit Hilfe dieser eingebundenen Elemente könne Sie sich über unsere
                Website die jeweiligen Inhalte anhören oder ansehen.
            </p>
            <p>
                Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können auch personenbezogene Daten von
                Ihnen an die Dienstanbieter übermittelt, verarbeitet und gespeichert werden.
            </p>
            <h3>Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</h3>
            <p>
                Natürlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass
                Inhalte nicht mehr bloß in Text und statischem Bild vermittelt werden. Statt Ihnen einfach nur einen
                Link zu einem Video zu geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate, die
                unterhaltend oder informativ und im Idealfall sogar beides sind. Das erweitert unser Service und
                erleichtert Ihnen den Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten und
                Bildern auch Video und/oder Audio-Inhalte an.
            </p>
            <h3>Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
            <p>
                Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein eingebettetes Video hat,
                verbindet sich Ihr Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von Ihnen an den
                Drittanbieter übertragen und dort gespeichert. Manche Daten werden ganz unabhängig davon, ob Sie bei dem
                Drittanbieter ein Konto haben oder nicht, gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse,
                Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu Ihrem Endgerät. Weiters werden von
                den meisten Anbietern auch Informationen über Ihre Webaktivität eingeholt. Dazu zählen etwa
                Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt haben oder über welche Website Sie den
                Dienst nutzen. All diese Informationen werden meist über Cookies oder Pixel-Tags (auch Web Beacon
                genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem Browser gespeichert.
                Welche Daten genau gespeichert und verarbeitet werden, erfahren Sie stets in der Datenschutzerklärung
                des jeweiligen Anbieters.
            </p>
            <h3>Dauer der Datenverarbeitung</h3>
            <p>
                Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erfahren Sie entweder
                weiter unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerklärung des Anbieters.
                Grundsätzlich werden personenbezogene Daten immer nur so lange verarbeitet, wie es für die
                Bereitstellung unserer Dienstleistungen oder Produkte unbedingt nötig wird. Dies gilt in der Regel auch
                für Drittanbieter. Meist können Sie davon ausgehen, dass gewisse Daten über mehrere Jahre auf den
                Servern der Drittanbieter gespeichert werden. Daten können speziell in Cookies unterschiedlich lange
                gespeichert werden. Manche Cookies werden bereits nach dem Verlassen der Website wieder gelöscht,
                anderen können über einige Jahre in Ihrem Browser gespeichert sein.
            </p>
            <h3>Widerspruchsrecht</h3>
            <p>
                Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
                Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über
                andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
                indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der
                Verarbeitung bis zum Widerruf bleibt unberührt.
            </p>
            <p>
                Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet
                werden, sollte Sie sich auch unsere allgemeine Datenschutzerklärung über Cookies durchlesen. In den
                Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie genaueres über den Umgang und die
                Speicherung Ihrer Daten.
            </p>
            <h3>
                <strong>Rechtsgrundlage</strong>
            </h3>
            <p>
                Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio- und Video-Elemente
                verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der
                Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch
                auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer
                schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und
                verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente gleichwohl nur ein, soweit Sie eine
                Einwilligung erteilt haben.
            </p>
            <h2 id='sonstiges-einleitung'>Sonstiges Einleitung</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Sonstiges Datenschutzerklärung Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Besucher der Website
                            <br />
                            &#x1f91d; Zweck: Verbesserung der Nutzererfahrung
                            <br />
                            &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den
                            verwendeten Diensten ab. Meist handelt es sich um IP-Adresse und/oder technische Daten. Mehr
                            Details dazu finden Sie bei den jeweils eingesetzten Tools.
                            <br />
                            &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit. f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was fällt unter „Sonstiges“?</h3>
            <p>
                Unter die Kategorie „Sonstiges“ fallen jene Dienste, die nicht in eine der oben genannten Kategorien
                passen. Dabei handelt es sich in der Regel um diverse Plugins und eingebundene Elemente, die unsere
                Website verbessern. In der Regel werden diese Funktionen von Drittanbietern bezogen und in unsere
                Website eingebunden. Beispielsweise handelt es sich dabei um Websuch-Dienste wie Algolia Place, Giphy,
                Programmable Search Engine oder Onlinedienste für Wetterdaten wie etwa OpenWeather.
            </p>
            <h3>Warum verwenden wir weitere Drittanbieter?</h3>
            <p>
                Wir wollen Ihnen mit unserer Website das beste Webangebot in unserer Branche bieten. Schon lange ist
                eine Website nicht bloß eine reine Visitenkarte für Unternehmen. Vielmehr ist es ein Ort, der Ihnen
                behilflich sein soll, zu finden wonach Sie suchen. Um stets unsere Website für Sie noch interessanter
                und hilfreicher zu machen, nutzen wir diverse Dienste von Drittanbietern.
            </p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>
                Immer wenn Elemente in unsere Website eingebunden werden, wird Ihre IP-Adresse an den jeweiligen
                Anbieter übermittelt, gespeichert und dort verarbeitet werden. Das ist nötig, weil sonst die Inhalte
                nicht an Ihren Browser gesendet werden und folglich nicht entsprechend dargestellt werden. Es kann auch
                vorkommen, dass Dienstanbieter auch Pixel-Tags bzw. Web-Beacons verwenden. Das sind kleine Grafiken auf
                Websites, die eine Logdatei aufzeichnen und auch Analysen dieser Datei erstellen können. Mit den
                erhaltenen Informationen können die Anbieter ihre eigenen Marketingmaßnahmen verbessern. Neben
                Pixel-Tags können solche Informationen (wie beispielsweise welchen Button Sie klicken oder wann Sie
                welche Seite aufrufen) auch in Cookies gespeichert werden. Darin können neben Analysedaten zu Ihrem
                Webverhalten auch technische Informationen wie etwa Ihr Browsertyp oder Ihr Betriebssystem gespeichert
                werden. Manche Anbieter können die gewonnenen Daten auch mit anderen internen Diensten oder auch mit
                Drittanbieter verknüpfen. Jeder Anbieter pflegt einen anderen Umgang mit Ihren Daten. Daher empfehlen
                wir Ihnen sorgfältig die Datenschutzerklärungen der jeweiligen Dienste durchzulesen. Wir sind
                grundsätzlich bemüht, nur Dienste zu verwenden, die mit dem Thema Datenschutz sehr vorsichtig umgehen.
            </p>
            <h3>Dauer der Datenverarbeitung</h3>
            <p>
                Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen
                dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung
                unserer Dienstleistungen und Produkte unbedingt notwendig ist.
            </p>
            <h3>Rechtsgrundlage</h3>
            <p>
                Wenn wir Sie um Ihre Einwilligung bitte und Sie auch einwilligen, dass wir den Dienst verwenden dürfen,
                gilt dies als Rechtsgrundlage der Verarbeitung Ihrer Daten (Art. 6 Abs. 1 lit. a DSGVO).  Zusätzlich zur
                Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der
                Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Die
                Rechtsgrundlage dafür ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Tools
                gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.
            </p>
            <p>
                Informationen zu den speziellen Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden
                Abschnitten.
            </p>
            <h2 id='google-fonts-datenschutzerklaerung'>Google Fonts Datenschutzerklärung</h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Google Fonts Datenschutzerklärung Zusammenfassung</strong>
                            <br />
                            &#x1f465; Betroffene: Besucher der Website
                            <br />
                            &#x1f91d; Zweck: Optimierung unserer Serviceleistung
                            <br />
                            &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen
                            <br />
                            Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.
                            <br />
                            &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert
                            <br />
                            &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                            lit. f DSGVO (Berechtigte Interessen)
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Was sind Google Fonts?</h3>
            <p>
                Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma
                Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow
                Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
            </p>
            <p>
                Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
                Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts)
                werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind
                die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein
                Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung
                von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style
                Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung
                genau aussieht, werden wir uns noch im Detail ansehen.
            </p>
            <p>
                Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die
                <a href='https://de.wikipedia.org/wiki/Google_LLC?tid=111943338'>Google</a>
                 Ihren Nutzern kostenlos zu Verfügung stellen.
            </p>
            <p>
                Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter
                der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
            </p>
            <h3>Warum verwenden wir Google Fonts auf unserer Website?</h3>
            <p>
                Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem
                eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch
                zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und
                ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite
                besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts
                sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern,
                Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte
                bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit
                Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser
                (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten
                modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir
                verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie
                möglich darstellen können.
            </p>
            <h3>Welche Daten werden von Google gespeichert?</h3>
            <p>
                Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch
                diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie
                bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung,
                Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche
                Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und
                dient unter anderem als Datenübermittler im Softwarebereich.
            </p>
            <p>
                Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
                gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die
                Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem
                verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten
                Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
                veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen
                untersuchen und bewegen zu können.
            </p>
            <p>
                Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen
                wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des
                Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist
                nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
            </p>
            <h3>Wie lange und wo werden die Daten gespeichert?</h3>
            <p>
                Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb
                der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
                nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die
                Schriftart einer Webseite ändern kann.
            </p>
            <p>
                Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit
                von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften
                verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen
                später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu
                reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
            </p>
            <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>
                Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die
                Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu
                können, müssen Sie den Google-Support auf
                <a href='https://support.google.com/?hl=de&amp;tid=111943338'>
                    https://support.google.com/?hl=de&amp;tid=111943338
                </a>
                kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
            </p>
            <p>
                Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
                können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
                rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
                <a href='https://developers.google.com/fonts/faq?tid=111943338'>
                    https://developers.google.com/fonts/faq?tid=111943338
                </a>
                . Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte
                Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich
                präzise Informationen über gespeicherten Daten zu bekommen.
            </p>
            <h3>Rechtsgrundlage</h3>
            <p>
                Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut
                <strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung
                personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.
            </p>
            <p>
                Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser
                Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist
                <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl
                nur ein, soweit Sie eine Einwilligung erteilt haben.
            </p>
            <p>
                Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
                Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA
                besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
                einhergehen.
            </p>
            <p>
                Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
                Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
                verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
                Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte
                Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
                entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert
                werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das
                europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und
                verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
                den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier:
                <a href='https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de' rel='noopener'>
                    https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
                </a>
            </p>
            <p>
                Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auch den
                Standardvertragsklauseln für Google Fonts entsprechen, finden Sie unter
                <a href='https://business.safety.google/adsprocessorterms/' rel='noopener'>
                    https://business.safety.google/adsprocessorterms/
                </a>
                .
            </p>
            <p>
                Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie
                auch auf
                <a href='https://policies.google.com/privacy?hl=de&amp;tid=111943338'>
                    https://www.google.com/intl/de/policies/privacy/
                </a>
                nachlesen.
            </p>
            <h2 id='google-fonts-lokal-datenschutzerklaerung'>Google Fonts Lokal Datenschutzerklärung</h2>
            <p>
                Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das
                Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir
                haben die Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den Servern von Google
                &#8211; eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine
                Datenübertragung oder Speicherung.
            </p>
            <h3>Was sind Google Fonts?</h3>
            <p>
                Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives
                Verzeichnis mit über 800 Schriftarten, die
                <a href='https://de.wikipedia.org/wiki/Google_LLC?tid=111943338'>Google</a>
                 kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server
                hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben
                wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform
                und senden keine Daten an Google Fonts weiter.
            </p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p>
                Quelle: Erstellt mit dem
                <a
                    href='https://www.adsimple.at/datenschutz-generator/'
                    title='Datenschutz Generator von AdSimple für Österreich'
                >
                    Datenschutz Generator
                </a>
                von AdSimple
            </p>
        </div>
    );
}
